module.exports = {
    home: {
        title: '奖金, 赠品, 折扣'
    },
    landing: {
        warranty: '24个月质保'
    },
    warranty: {
        title: '提醒: 我们不会泄漏你的个人信息给第三方',
        name: '姓名: ',
        nameAlert: '姓名不得超过32个字符',
        email: '邮箱: ',
        emailAlert: '邮箱不得超过32个字符',
        emailAlert2: '请输入正确的邮箱',
        order: '亚马逊订单号: ',
        orderAlert: '请输入正确的亚马逊订单号',
        market: '购买平台: ',
        interested: '有兴趣参加我们免费的新品测试吗?',
        submit: '提交'
    },
    finished: {
        title: '你的质保申请已经收到, 谢谢'
    },
    alert: {
        90001: '该亚马逊订单号不存在, 请输入正确的亚马逊订单号.',
        90002: '请选择该订单号正确的购买平台.',
        90003: '该订单号的质保已经生效, 不需要重复提交.'
    }
}