module.exports = {
    home: {
        title: 'Herzlichen Glückwunsch!'
    },
    redeem: {
        tip1: 'Hinweis: Jeder Custom kann nur einmal eingelöst werden\n' +
            'Unser Unternehmen behält sich das Recht der endgültigen Erklärung vor.',
        tip2: 'Fangen wir jetzt an.\n' +
            'Bitte geben Sie Ihren Einlösecode ein: ',
        code: 'Code einlösen ',
        codeAlert: 'Bitte geben Sie den korrekten Gutscheincode ein',
    },
    warranty: {
        name: 'Name:',
        nameAlert: 'Der Name darf 32 Zeichen nicht überschreiten.',
        email: 'E-Mail:',
        emailAlert: 'Bitte geben Sie eine gültige E-Mail-Adresse für Annahme den Gutschein ein.',
        emailAlert2: 'Bitte geben Sie eine gültige E-Mail-Adresse für Annahme den Gutschein ein.',
        order: 'Amazon-Bestellnummer::',
        orderTip: 'Beispiel：304-1234567-1234567（17 Figuren）',
        orderAlert: 'Bitte geben Sie Ihre korrekte Amazon-Bestellnummer ein.',
        otherContactTip: 'Hinweis: Bitte hinterlassen Sie Ihre WhatsApp-ID bzw. Ihre Handynummer, falls wir Sie nicht per E-Mail erreichen können.',
        whatsapp: 'ID WhatsApp:',
        whatsappTip: 'Beispiel: +49 1701234567',
        warningTip: 'Hinweis: Ihre Informationen werden streng vertraulich behandelt. Mach dir keine Sorge.',
        otherContactAlert: 'Bitte hinterlassen Sie Ihre WhatsApp-ID bzw. Ihre Handynummer, falls wir Sie nicht per E-Mail erreichen können.'
    },
    finished: {
        title1: 'Vielen Dank für die Mühe\n\nWir senden Ihnen den Amazon-Geschenkkartencode zum Einlösen, sobald Ihre Rezension veröffentlicht wurde. Normalerweise dauert es 3-5 Tage.',
        dissatisfied: 'Vielen Dank, dass Sie sich die Zeit genommen haben, uns eine wichtig Feedback teilen.'
    },
    survey: {
        title: 'Sind Sie mit unserem Produkt zufrieden?',
        option1: 'Ja, zufrieden',
        option2: 'Nein, nicht zufrieden',
        dissatisfiedAlert: 'Sind Sie mit unserem Produkt zufrieden?',
        reportTip1: 'Teilen Sie uns Ihre Unzufriedenheit mit, damit wir unser Produkt weiter verbessern können. Wir stehen hinter unseren Produkten und werden unser Bestes tun, um Ihr Problem zu lösen.',
        reportAlert: 'Bitte teilen Sie Ihre Meinung, damit wir Ihnen weiter helfen können.'
    },
    review: {
        title: 'Es ist uns eine Ehre',
        reviewTip1: 'Könnten Sie sich etwas Zeit nehmen, um eine Bewertung für unser Produkt hinterzulassen? Hängen Sie auch den Screenshot Ihrer Bewertung an.',
        reviewStep1: '1. Navigieren Sie Meine Bestellungen navigieren.',
        reviewStep2: '2. Klicke unter „Kundenrezensionen“ auf Kundenrezension verfassen.',
        reviewStep3: '3. Wähle die gewünschte Anzahl an Sternen aus.\n Für erfolgreich eingereichte Bewertungen wird ein grünes Häkchen angezeigt.',
        reviewStep4: '4. (Optional) Füge Text, Fotos oder Videos hinzu und klicke auf Senden. (Fügen Sie nicht das Bild der Rubbelkarte hinzu.)',
        reviewStep5: '5. Senden Sie uns Screenshots Ihrer Rezension, damit sie von anderer unterscheidbar ist.',
        reviewStep41: 'Variante Screenshot ①',
        reviewStep42: 'Variante Screenshot ②',
        reviewStep43: 'Variante Screenshot ③',
        reviewTip2: 'Wir senden Ihnen den Gutschein zum Einlösen, sobald Ihre Bewertung auf der Amazon-Bewertungsseite veröffentlicht wurde.',
        reviewTip3: 'Normalerweise dauert es 2-5 Tage.',
        imageTip: 'Klicken Sie hier, um den Screenshot Ihrer Bewertung bereitzustellen',
        imageAlert: 'Überprüfen Sie das angeforderte Bild.',
        dialog: {
            title: 'Bestätigung',
            cancel: 'Falsch, erneut senden',
            submit: 'absenden'
        }
    },
    alert: {
        90001: 'Diese Bestellnummer ist bereits angemeldet',
        90002: 'Stellen Sie sicher, dass Ihre Bestellung auf dem Marktplatz Amazon.de aufgegeben wurde.',
        90003: 'Ihre Rezension wartet auf Amazon. Es dauert normalerweise 3-5 Tage. Bitte haben Sie Geduld.',
        90004: 'Jeder Kunde kann nur einmal einlösen. Danke für Ihr Verständnis.',
        90005: 'Vielen Dank für Ihre anhaltende Unterstützung. Entschuldigung, aber Ihre Bestellnummer ist nicht für die Rubbellos-Aktion berechtigt. (Personen, die per Tweet am Wettbewerb teilnehmen oder Kundendienstunterstützung anfordern, können nicht am Wettbewerb teilnehmen). Bitte beachten Sie jedoch, dass wir dieser Anfrage in Zukunft nicht mehr nachkommen können. Wir danken Ihnen für Ihr Verständnis.',
    },
    next: 'Nächste',
    previous: 'Zurück',
    submit: 'Bestätigen',
}