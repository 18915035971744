module.exports = {
    home: {
        title: 'bonus, cadeau, réduction'
    },
    landing: {
        warranty: 'Garantie 24 mois'
    },
    warranty: {
        title: 'Rappel: Nous ne divulguerons pas vos informations personnelles à des tiers',
        name: 'Nom: ',
        nameAlert: 'Le nom ne doit pas dépasser 32 caractères',
        email: 'E-mail : ',
        emailAlert: 'Ladresse e-mail ne doit pas dépasser 32 caractères',
        emailAlert2: 'Veuillez saisir l',
        order: 'numéro de commande Amazon : ',
        orderAlert: 'Veuillez entrer le bon numéro de commande Amazon',
        market: 'plateforme dachat :',
        interested: 'Vous souhaitez participer à notre test gratuit de nouveaux produits?',
        submit: 'Soumettre'
    },
    finished: {
        title: 'Votre demande de garantie a été reçue, merci'
    },
    alert: {
        90001: 'Le numéro de commande Amazon n\'existe pas, veuillez saisir le bon numéro de commande Amazon.',
        90002: 'Veuillez sélectionner la bonne plateforme d\'achat pour ce numéro de commande.',
        90003: 'La garantie pour ce numéro de commande a déjà pris effet, pas besoin de resoumettre.'
    }
}