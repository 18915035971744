import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'DE',
    fallbackLocale: 'DE',
    messages: {
        US: require('./trans/us.js'),
        DE: require('./trans/de.js'),
        FR: require('./trans/fr.js'),
        ES: require('./trans/es.js'),
        IT: require('./trans/it.js'),
        CA: require('./trans/us.js'),
        UK: require('./trans/uk.js'),
        AU: require('./trans/us.js'),
        JP: require('./trans/jp.js'),
        CN: require('./trans/cn.js')
    }
})

export default i18n