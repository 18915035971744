//引入vue
import Vue from 'vue';
//引入vue-router
import VueRouter from 'vue-router';
import WarrantyPage from "@/Pages/WarrantyPage";
import FinishedPage from "@/Pages/FinishedPage";
import RedeemPage from "@/Pages/RedeemPage";
import ReviewPage from "@/Pages/ReviewPage";
import SurveyPage from "@/Pages/SurveyPage";
import trialFinishedPage from "@/Pages/TrialFinishedPage";
//第三方库需要use一下才能用
Vue.use(VueRouter)
const routes = [
    { path: "/", component: RedeemPage },
    { path: "/warranty/:lang", component: WarrantyPage },
    { path: "/finished", component: FinishedPage },
    { path: "/review", component: ReviewPage },
    { path: "/survey", component: SurveyPage },
    { path: "/trial/finished", component: trialFinishedPage },
]

const router = new VueRouter({
    mode: 'history',
    routes
});


/*router.beforeEach((to, from, next) => {
    Vue.prototype.$bus.$emit("closeMsg");

    // 路由变化时取消当前所有非全局的pending状态的请求
    Vue.prototype.$axios.clearPendingPool();
    if (to.path !== "/login" && !localStorage.token) {
        next({path: "/login"});
    } else {
        next();
    }
});*/


export default router;